<!-- Mto particular de Funerarias Centrales -->

<template>
  <div class="funec_M"  v-if="schema">

    <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>

      <v-sheet :elevation="4">
        <!-- Botones Mto -->
        <div class="pt-2 d-flex justify-left">

          <!-- Mto -->            
          <btramto
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="m"
              :estado="estado"
              @onEvent="event_btra_Mto">        
          </btramto>

        </div>

        <div class="contenedor" style="width:920px">      
          <div class="columna">
            <div class="conflex">
              <div class="columna" style="width:70%">
                <v-sheet v-bind="$cfg.styles.marco">
                  <ctrlcontact
                    :schema="schema"
                    :name="schema.ctrls.name"
                    :edicion="is_edit">
                  </ctrlcontact>
                </v-sheet>
              </div>
           
              <div class="columna"  style="width:30%;padding-left:10px">
                <div class="cab">CLASIFICACIÓN</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-select
                    style="flex: 1 1 100%"
                    v-bind="$select"
                    :items="$store.state.datos_iniciales.situ"
                    :disabled="!is_edit"
                    item-value="id"
                    item-text="label"
                    v-model="schema.ctrls.situ.value"
                    :label="schema.ctrls.situ.label">
                  </v-select>

                  <v-select
                    style="flex: 1 1 100%"
                    v-bind="$select"
                    v-model="schema.ctrls.tipo.value"
                    :items="$store.state.datos_iniciales.tipo"
                    :disabled="!is_edit"
                    item-value="id"
                    item-text="name"
                    :label="schema.ctrls.tipo.label">
                  </v-select>
                </v-sheet>
               
                <div class="cab">GRUPO</div>
                <v-sheet width="100%" v-bind="$cfg.styles.marco">
                  <v-select
                    style="flex: 1 1 100%"
                    v-bind="$select"
                    v-model="schema.ctrls.grup_id.value"
                    :label="schema.ctrls.grup_id.label"
                    :items="grupos"
                    :disabled="!is_edit"
                    item-value="id"
                    item-text="name">
                  </v-select>
                </v-sheet>            
              </div>
            </div>
            
            
            <div class="conflex" style="padding-top:10px">
              <div class="columna" style="width:50%">
                <div class="cab">DATOS FUNERARIA</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <div class="conflex">
                    <v-text-field
                      style="flex: 1 1 70%"
                      v-bind="$input"
                      :label="schema.ctrls.razon.label"
                      v-model="schema.ctrls.razon.value"
                      :disabled="!is_edit">
                    </v-text-field>
              
                    <v-text-field
                      style="flex: 1 1 30%"
                      v-bind="$input"
                      :label="schema.ctrls.ccont.label"
                      v-model="schema.ctrls.ccont.value"
                      :disabled="!is_edit">
                    </v-text-field>           
                  </div>

                  <div class="conflex">
                    <v-text-field
                      v-bind="$input"
                      :label="schema.ctrls.tlf1.label"
                      v-model="schema.ctrls.tlf1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      v-bind="$input"
                      :label="schema.ctrls.movil.label"
                      v-model="schema.ctrls.movil.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      v-bind="$input"
                      :label="schema.ctrls.fax1.label"
                      v-model="schema.ctrls.fax1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.visarec.value"
                      :label="schema.ctrls.visarec.label"
                      :disabled="!is_edit">
                    </v-checkbox>
                  </div>

                  <div class="conflex">
                    <v-text-field
                      style="flex: 1 1 70%"
                      v-bind="$input"
                      :label="schema.ctrls.cont1.label"
                      v-model="schema.ctrls.cont1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      style="flex: 1 1 30%"
                      v-bind="$input"
                      :label="schema.ctrls.diredni.label"
                      v-model="schema.ctrls.diredni.value"
                      :disabled="!is_edit">
                    </v-text-field>
                  </div>

                  <div class="conflex">
                    <v-text-field
                      v-bind="$input"
                      :label="schema.ctrls.email1.label"
                      v-model="schema.ctrls.email1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      v-bind="$input"
                      :label="schema.ctrls.email2.label"
                      v-model="schema.ctrls.email2.value"
                      :disabled="!is_edit">
                    </v-text-field>
                  </div>

                  <div class="conflex">
                    <ctrlurl 
                      style="flex: 1 1 100%"
                      :schema="schema.ctrls.url" 
                      :edicion="is_edit"> 
                    </ctrlurl>
                  </div>
                </v-sheet>
              </div>
            
              <div class="columna" style="width:50%;padding-left:10px">
                <div class="cab">ADMINISTRACIÓN</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-text-field
                    v-bind="$input"
                    :label="schema.ctrls.admoncont.label"
                    v-model="schema.ctrls.admoncont.value"
                    :disabled="!is_edit">
                  </v-text-field>
                  

                  <div class="conflex">
                    <v-text-field
                      style="flex: 1 1 20%"
                      v-bind="$input"
                      :label="schema.ctrls.admontlf1.label"
                      v-model="schema.ctrls.admontlf1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      style="flex: 1 1 20%"
                      v-bind="$input"
                      :label="schema.ctrls.admonfax1.label"
                      v-model="schema.ctrls.admonfax1.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      style="flex: 1 1 60%"
                      v-bind="$input"
                      :label="schema.ctrls.admonemail.label"
                      v-model="schema.ctrls.admonemail.value"
                      :disabled="!is_edit">
                    </v-text-field>
                  </div>

                  <div class="conflex">
                    <v-text-field
                      style="flex: 1 1 30%"
                      v-bind="$input"
                      :label="schema.ctrls.ban.label"
                      v-model="schema.ctrls.ban.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      style="flex: 1 1 50%"
                      v-bind="$input"
                      :label="schema.ctrls.cta.label"
                      v-model="schema.ctrls.cta.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <v-text-field
                      style="flex: 1 1 20%"
                      v-bind="$input"
                      :label="schema.ctrls.conta.label"
                      v-model="schema.ctrls.conta.value"
                      :disabled="!is_edit">
                    </v-text-field>

                    <!-- <v-btn
                      v-bind="$cfg.btra_cfg.standard"            
                      title="Filtrar Facturas">
                        VTO
                    </v-btn> -->
                  </div>

                  <ctrlobs3
                    :schemaComponente="schema.ctrls.obs"
                    :schema="schema"   
                    :record="record"         
                    :edicion="is_edit"
                    row="3">
                  </ctrlobs3>
                </v-sheet>
              </div>
            </div>
            
          </div>
        </div>
      </v-sheet>
   
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: { base_Header, btramto, ctrlcontact, ctrlurl, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },


    data() {
      return {
        schema: null,
        api:'funec_M',
        stName:'stMfunec',
        disparoFinder:false, 

        grupos:[],
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        // entorno
        this.Entorno.header.titulo = "Funerarias Centrales";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));

        //combo grupos añadir sin grupo
        this.grupos=this.$store.state.datos_iniciales.grupos.slice();
        this.grupos.unshift({id:'0',name:'* SIN GRUPO ASIGNADO'});
      },

    


      // gestor de eventos
     /*  event_capture(evt) {
        console.log("*** event_capture funec_M. Accion: " + evt.accion + " ***");

        if (this.$isFunction(this[evt.accion])) {
          this[evt.accion](evt);
          return;
        }
        this.acciones_Mto(evt);
      }, */


      // configuro entorno Mto particular
      /* get_entorno() {
        this.set_btra();
        this.set_btraExtra();

        if (!this.Entorno.header.titulo)
          this.Entorno.header.titulo = "Mantenimiento Funerarias Centrales";
        this.Entorno.api = "funec_M";
        this.Entorno.header.style += ";min-width:60rem";
      }, */

      //
      /* set_btraExtra() {} */
    }
  };
</script>
